const numberDate = true;

export default [
  {
    title: 'УСТАНОВЛЕНИЕ СОБСТВЕННИКА',
    params: [
      {
        title: 'Собственник известен',
        key: 'owner_is_know'
      },
      {
        numberDate,
        title: 'Запрос на установление собственника №',
        key:
          'request_establishment_owner_number request_establishment_owner_date'
      },
      {
        title: 'Результат',
        key: 'request_establishment_owner_result'
      },
      {
        files: '',
        title: 'ДОКУМЕНТЫ',
        key: 'file_request_establishment_owner'
      },
      {
        if: true,
        func: protocol => {
          return {
            value: protocol.request_establishment_owner_result == true,
            template: 'base'
          };
        },
        title:
          'Сведения о лице, в отношении которого возбуждено дело об административном правонарушении',
        key: 'place_owner_person_info'
      },
      {
        if: true,
        func: protocol => {
          return {
            value: protocol.request_establishment_owner_result == true,
            template: 'base'
          };
        },
        title:
          'Статус лица, в отнощении которого возбуждено дело об административном правонарушении',
        key: 'place_owner_person_status'
      }
    ]
  },
  {
    title: 'ОБРАЩЕНИЕ В УМВД',
    params: [
      {
        numberDate,
        title: 'Обращение в УМВД №',
        key: 'appeal_to_umvd_number appeal_to_umvd_date'
      },
      {
        files: true,
        title: 'ДОКУМЕНТЫ',
        key: 'file_appeal_to_umvd_docs'
      },
      {
        title: 'Решение УМВД',
        key: 'decision_umvd_text'
      },
      {
        files: '',
        title: 'ДОКУМЕНТЫ',
        key: 'file_decision_umvd_docs'
      }
    ]
  },
  {
    title: 'ОПЛАТА ШТРАФА',
    params: [
      {
        title: 'Сумма назначенного штрафа, руб.',
        key: 'amount_of_penalty_object_owner'
      },
      {
        title: 'Сумма оплаченного штрафа, руб.',
        key: 'amount_of_penalty_object_owner_paid'
      }
    ]
  }
];
